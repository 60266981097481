import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router';
import { NotFound } from '../loadable-elements/NotFound';
import ConsumerTestGuide from '../pages/ConsumerTestGuide/ConsumerTestGuide';

export const ConsumerTestRouter = (): ReactElement => (
  <Switch>
    {/* Render a specific consumer test guide */}
    <Route exact path="/forbrukertester/:slug">
      <ConsumerTestGuide />
    </Route>

    {/* To do: Route to specific tested product */}
    {/* <Route exact path="/forbrukertester/:slug">
      <ConsumerTestGuide />
    </Route> */}

    {/* Fallback for invalid paths */}
    <Route>
      <NotFound />
    </Route>
  </Switch>
);
