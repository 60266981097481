import React from 'react';
import styled from 'styled-components';
import { nafColor } from '@nafcore/theme';
import { breakpoints } from '@naf/theme';

export const ConsumerTestPlaceholder = () => (
  <ListElement>
    <ElementImagePlaceholder />
    <ElementInfo>
      <Text />
      <ElementPrice />
    </ElementInfo>
  </ListElement>
);

const ListElement = styled.a`
  text-decoration: none;
  position: relative;
  overflow: hidden;
  display: flex;
  padding: 32px 0;
  width: 100%;
  border-bottom: 1px solid lightgrey;

  :first-of-type {
    padding-top: 0;
  }

  ::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }

  @keyframes load {
    from {
      left: -150px;
    }
    to {
      left: 100%;
    }
  }

  &:hover {
    border-radius: 4px;

    img {
      transform: scale(1.05);
    }
    h3 {
      &::after {
        width: 100%;
      }
    }
  }
`;

const ElementImagePlaceholder = styled.div`
  overflow: hidden;
  border-radius: 2px;
  margin: 0 32px 0 0;
  width: 320px;
  position: relative;
  height: 266px;
  background-color: ${nafColor && nafColor.neutral.neutral1 ? nafColor.neutral.neutral1 : `#F2F2F2`};

  @media (max-width: ${breakpoints.s}) {
    max-width: 100px;
    height: calc(100px / (16 / 9));
    margin-right: 24px;
  }

  ::after {
    display: block;
    content: '';
    /* 4:3 aspect ratio */
    padding-top: 75%;

    @media (max-width: ${breakpoints.m}) {
      padding-top: 56.25%;
    }
  }
  z-index: 0;

  img {
    transition: transform 0.6s cubic-bezier(0.23, 1, 0.32, 1);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 105%;

    object-fit: cover;
  }
`;

const Text = styled.div`
  width: 150px;
  background-color: ${nafColor && nafColor.neutral.neutral1 ? nafColor.neutral.neutral1 : `#F2F2F2`};
  height: 50px;
`;

const ElementInfo = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    width: auto;

    @media (min-width: ${breakpoints.m}) {
      width: max-content;
    }

    position: relative;
    margin: 0;
    &::after {
      content: '';
      width: 0;
      height: 2px;
      transition: width 0.3s cubic-bezier(0.23, 1, 0.32, 1);
      background-color: #ffdb16;
      position: absolute;
      left: 0;
      bottom: -4px;
    }
  }
`;

const ElementPrice = styled.strong`
  margin-top: 16px;
  font-weight: 500;
  font-size: 1.25rem;
  background-color: ${nafColor && nafColor.neutral.neutral1 ? nafColor.neutral.neutral1 : `#F2F2F2`};
  height: 29.6px;
  width: 100px;
`;

export default ConsumerTestPlaceholder;
