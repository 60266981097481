import React, { useEffect } from 'react';
import { Grid, GridCol } from '@naf/grid';
import { Text, TextVariant } from '@naf/text';
import { Button } from '@naf/button';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useAppInsightsContext, useTrackMetric } from '@microsoft/applicationinsights-react-js';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { useCloudinary } from '../../hooks/useCloudinary';
import { actions as guides } from '../../redux/modules/consumerGuides';
import { LayoutWithMainContent } from '../../components/layout/Layout';
import BreadCrumb from '../../components/breadcrumb/BreadCrumb';
import { useWindowLocation } from '../../hooks/useWindowLocation';
import { InternalSearchCardType } from '../../components/layout/InternalSearchMetaData';
import useSelector from '../../redux/typedHooks';
import { useAuth0Token } from '../../hooks/useAuth0Token';
import TestList from '../../components/ConsumerTest/TestList/TestList';
import TestSearchSort from '../../components/ConsumerTest/TestSearchSort/TestSearchSort';
import { LazyImage } from '../../components/lazy-image/LazyImage';
import { NotFound } from '../errors/NotFound';
import * as S from './Styles';
import ConsumerTestPlaceholder from './Components/Placeholder/ConsumerTestPlaceholder';

const ConsumerTestGuide = () => {
  const { simpleToken } = useAuth0Token();
  const dispatch = useDispatch();
  const cld = useCloudinary();
  const appInsights = useAppInsightsContext();
  const windowPath = useWindowLocation();
  const { slug } = useParams<{ slug: string }>();
  const { selectedGuide, isUpdatingGuide, isUpdatingProducts, testedProducts, errorState } = useSelector(
    (state) => state.consumerGuides,
  );
  useTrackMetric(appInsights, 'Forbrukertest');

  // Request the guide entered.
  useEffect(() => {
    if (slug && (!selectedGuide || selectedGuide.slug !== slug)) {
      dispatch(guides.getOneGuide.request(slug, { token: simpleToken || undefined }));
    }
  }, [selectedGuide, slug, dispatch, simpleToken]);

  // Always request tested products when selectedGuide changes.
  useEffect(() => {
    if (selectedGuide && selectedGuide.id) {
      dispatch(guides.getTestedProducts.request(selectedGuide.id, { token: simpleToken }));
    }
  }, [selectedGuide, dispatch, simpleToken]);

  const { title, ingress, image } = selectedGuide;
  const modifyCloudinaryImageUrlToThumbnail = (path: string) => path.replace('/upload/v', '/upload/c_scale,w_330/v');
  const guideImage =
    image &&
    image.publicId &&
    cld.image(image.publicId).resize(fill().width(324).height(282)).quality('auto:good').format('auto');

  if (!selectedGuide) {
    return (
      <h1
        style={{
          minHeight: '600px',
          margin: 'auto',
          marginTop: '200px',
        }}
      >
        Fant ikke guiden!
      </h1>
    );
  }

  if (errorState) {
    return <NotFound />;
  }

  return (
    <LayoutWithMainContent
      title={title}
      description={ingress}
      url={windowPath}
      gtmArgs={{
        page_type: 'ConsumerTestPage',
        pageCategory: 'Forbrukertest',
        contentId: slug,
      }}
      internalSearchMetaData={{ cardType: InternalSearchCardType.Simple }}
    >
      <S.Wrapper>
        <Grid>
          <GridCol s="12" m="12" l="12" xl="12">
            <BreadCrumb />
            <S.TopWrapper>
              {isUpdatingGuide ? (
                <ConsumerTestPlaceholder />
              ) : (
                <>
                  <S.ImageWrapper>
                    {guideImage ? (
                      <LazyImage
                        actualSrc={modifyCloudinaryImageUrlToThumbnail(image?.srcSet?.jpgLarge || '')}
                        altText={image?.altText || ''}
                        displayName={title}
                      />
                    ) : null}
                  </S.ImageWrapper>
                  <S.TopWrapperTextContent>
                    <Text tag="h1" variant={TextVariant.Header1}>
                      {title}
                    </Text>
                    <S.IngressWrapper>
                      <Text tag="p" variant={TextVariant.Ingress}>
                        {ingress}
                      </Text>
                    </S.IngressWrapper>
                    {/* The href for this should be from the api. */}
                    <Button variant="secondary" href="/forbrukertester">
                      Les mer om testen her
                    </Button>
                  </S.TopWrapperTextContent>
                </>
              )}
            </S.TopWrapper>
            {/* Sorting  */}
            <TestSearchSort itemsCount={testedProducts?.itemsCount ?? 0} />
          </GridCol>
          <S.LargeGrid>
            <GridCol l="4" xl="4">
              <S.TestFilterWrapper>{/* <TestFilter /> */}</S.TestFilterWrapper>
            </GridCol>
            <GridCol l="8" xl="8">
              <S.TestListWrapper>
                <TestList testedProducts={testedProducts?.items ?? []} isUpdatingProducts={isUpdatingProducts} />
              </S.TestListWrapper>
            </GridCol>
          </S.LargeGrid>
          <S.SmallGrid>
            <GridCol s="12" m="12">
              <S.TestListWrapper>
                <TestList testedProducts={testedProducts?.items ?? []} isUpdatingProducts={isUpdatingProducts} />
              </S.TestListWrapper>
            </GridCol>
          </S.SmallGrid>
        </Grid>
      </S.Wrapper>
    </LayoutWithMainContent>
  );
};

export default ConsumerTestGuide;
