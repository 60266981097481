import React from 'react';
import TestListItem from '../TestListItem/TestListItem';
import { TestedProductsResponse } from '../../../../../types/consumerTestType';

interface Props {
  testedProducts: TestedProductsResponse['items'];
  isUpdatingProducts: boolean;
}

const TestList: React.FC<Props> = ({ testedProducts = [], isUpdatingProducts }) => {
  if (isUpdatingProducts) {
    return <TestListItem isUpdatingProducts testedProducts={null} key="loading-placeholder" />;
  }

  return (
    <>
      {testedProducts.map((product) => (
        <TestListItem testedProducts={product} key={`tested-product-${product.title}`} isUpdatingProducts={false} />
      ))}
    </>
  );
};

export default TestList;
