import React from 'react';
import styled from 'styled-components';
import { breakpoints, radius, spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import { Link } from 'react-router-dom';
import { Label } from '@naf/label';
import { Text, TextVariant } from '@naf/text';
import CarSubListPlaceholder from '../../bilguide/carlist/CarSubListPlaceholder';
import { LazyImage } from '../../lazy-image/LazyImage';
import { TestedProduct } from '../../../../../types/consumerTestType';

interface Props {
  testedProducts: TestedProduct | null;
  isUpdatingProducts?: boolean;
}

const TestListItem = ({ testedProducts, isUpdatingProducts }: Props) => {
  if (isUpdatingProducts) {
    return <CarSubListPlaceholder key="loading-placeholder" />;
  }

  const modifyCloudinaryImageUrlToThumbnail = (path: string) => path.replace('/upload/v', '/upload/c_scale,w_330/v');
  const scoreLabel = (scoreValue: number, maxValue: number) => `${scoreValue} poeng av ${maxValue}`;

  const { title, slug, score, price, parameterLabels } = testedProducts;

  return (
    <Wrapper>
      <ListElement to={`/forbrukertester/${slug}`}>
        <ElementImage>
          <LazyImage
            actualSrc={
              testedProducts.image?.srcSet?.jpgLarge
                ? modifyCloudinaryImageUrlToThumbnail(testedProducts?.image?.srcSet?.jpgLarge)
                : ''
            }
            displayName={title}
            altText={testedProducts.image?.alt || testedProducts.image?.caption || ''}
          />
        </ElementImage>
        {score && (
          <StyledLabel
            variant={score.isWinner ? 'signature' : 'moss'}
            text={score.isWinner ? 'Testvinner' : scoreLabel(score.scoreValue ?? 0, score.maxValue ?? 10)}
          />
        )}
        <ElementInfo>
          <Text tag="h3" variant={TextVariant.Header3}>
            {title}
          </Text>
          <LabelList>
            {parameterLabels.map((parameterLabel: string) => (
              <LabelListElement key={`${testedProducts.id}-label-${parameterLabel}`}>{parameterLabel}</LabelListElement>
            ))}
          </LabelList>
          <PriceInfoWrapper>
            <ElementPrice>fra {price?.toLocaleString() || '-'},-</ElementPrice>
          </PriceInfoWrapper>
        </ElementInfo>
      </ListElement>
    </Wrapper>
  );
};

export default TestListItem;

const Wrapper = styled.div`
  padding-top: ${spacing.space32};

  :first-of-type {
    padding-top: 0;
  }
`;

// eslint-disable-next-line react/jsx-props-no-spreading
const ListElement = styled((props) => <Link {...props} />)`
  cursor: pointer;
  text-decoration: none;
  display: flex;
  padding: ${spacing.space32} 0;
  border-bottom: 1px solid lightgrey;

  :first-of-type {
    padding-top: 0;
  }

  &:hover {
    border-radius: 4px;
    color: ${({ theme }) => (theme && theme.textLink?.color ? theme.textLink?.color : `#1a1a1a`)} !important;

    img,
    svg {
      transform: scale(1.05);
    }

    h3 {
      &::after {
        width: 100%;
      }
    }
  }

  @media (max-width: ${breakpoints.s}) {
    flex-direction: column;
  }
`;

const StyledLabel = styled(Label)`
  position: absolute;
  margin-top: -${spacing.space12};
  margin-left: ${spacing.space12};
`;

const ElementImage = styled.figure`
  z-index: 0;
  overflow: hidden;
  position: relative;
  border-radius: ${radius.s};
  margin: 0 ${spacing.space32} 0 0;
  width: 220px;
  height: 166px;

  img {
    transition: transform 0.6s cubic-bezier(0.23, 1, 0.32, 1);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 106%;
    object-fit: cover;
  }

  @media (max-width: ${breakpoints.s}) {
    width: 100%;
    height: auto;
    margin: 0 0 ${spacing.space32} 0;
    aspect-ratio: 4 / 3;
  }
`;

const ElementInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  h3 {
    width: auto;

    @media (min-width: ${breakpoints.s}) {
      width: max-content;
    }

    @media (max-width: ${breakpoints.s}) {
      font-size: 1.25rem;
    }

    position: relative;
    margin: 0;
    &::after {
      content: '';
      width: 0;
      height: 2px;
      transition: width 0.3s cubic-bezier(0.23, 1, 0.32, 1);
      background-color: #ffdb16;
      position: absolute;
      left: 0;
      bottom: -4px;
    }
  }
`;

const PriceInfoWrapper = styled.div`
  margin-top: auto;
  margin-bottom: -6px;
`;

const ElementPrice = styled.strong`
  margin-top: 1rem;
  font-weight: 500;
  font-size: 1.125rem;

  @media (max-width: ${breakpoints.s}) {
  }
`;

const LabelList = styled.ul`
  padding-left: 0;
  list-style: none;
  margin-top: 8px;
  font-size: 1rem;
`;

const LabelListElement = styled.li`
  display: inline-block;
  color: ${nafColor && nafColor.neutral.neutral6 ? nafColor.neutral.neutral6 : `#4D4D4D`};
  padding-right: 8px;

  &:not(:last-child)::after {
    content: '\\2022';
    color: #c1c0c1;
    padding-left: 8px;
  }

  &:last-child ::after {
    content: '';
  }
`;
